body {
    background: #ececec;
}

.header {
    display: flex;
    height: 100px;
    background: #007bff;
    justify-content: center;
    align-items: center;
}

.header h1 {
    color: white;
    font-size: 170%;
}

.footer {
    margin-top: 2rem;
    height: 100px;
    text-align: center;
}
