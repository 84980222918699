.book {
    display: flex;
    padding: 1rem;
}

.book .book-img-container {
    width: 120px;
    margin-right: 1rem;
}

.book .book-img-container img {
    width: 100%;
}

.book .book-title {
    display: block;
    font-size: 120%;
}

.book .book-authors,
.book .book-publisher,
.book .book-published-date,
.book .book-isbn{
    display: block;
    font-size: 90%;
}
